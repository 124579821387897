@font-face {
  font-family: 'Varela Round', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: 'Varela Round', sans-serif !important;
}
